export const TAX_NUMBERS: TAX_TYPES = {
  GST: '760780494 RT0001',
  HST: '760780494 RT0001',
  QST: '1224001084 TQ0001',
};

export interface TAX_TYPES {
  GST?: string;
  HST?: string;
  QST?: string;
}

export const sortTaxes = (taxes: TAX_TYPES) => {
  const entries = Object.entries(taxes);

  const sortedEntries = entries.sort(([keyA], [keyB]) =>
    keyA.localeCompare(keyB),
  );

  return Object.fromEntries(sortedEntries);
};
