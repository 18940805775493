import { Grid, List, ListItem } from '@mui/material';
import clsx from 'clsx';
import { receiptStyles } from './styles';
import { TAX_NUMBERS, TAX_TYPES, sortTaxes } from './utils';

interface IPROPS {
  taxRates?: TAX_TYPES;
}

const TaxNumbers = ({ taxRates = {} }: IPROPS) => {
  const classes = receiptStyles();
  const renderTaxItem = (key: string) => {
    return (
      <ListItem
        className={clsx(
          classes.mt4,
          classes.p0,
          classes.font14,
          classes.gray6,
          classes.weight400,
          classes.lineHeight20,
        )}
      >
        {`${key}#: ${TAX_NUMBERS[key]}`}
      </ListItem>
    );
  };

  return (
    <Grid container>
      <List className={classes.p0}>
        {Object.keys(sortTaxes(taxRates)).map((key) => renderTaxItem(key))}
      </List>
    </Grid>
  );
};

export default TaxNumbers;
