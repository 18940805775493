import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { forwardIcon } from '../../../assets/icons/icons';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { settinStyles } from './styles';

interface IProps {
  classes: ClassNameMap;
  title: string;
  path?: string;
  subTitle?: string;
  icon: (value) => any;
  isRouteGetHelp?: boolean;
  externalPath?: string;
  ariaLabel?: string;
}

const AccountMenuItem = ({
  classes,
  path,
  title,
  subTitle,
  icon,
  isRouteGetHelp = false,
  externalPath,
  ariaLabel,
}: IProps) => {
  const theme = useSelector(getTheme);
  const navigate = useNavigate();
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const handleRoute = () => {
    if (path) {
      navigate(
        `/app/${window.domainName}/${path}${
          chargerName ? `?charger=${chargerName}` : ''
        }`,
      );
    } else if (isRouteGetHelp) {
      window.open('https://chargelab.zendesk.com/hc/en-us');
    } else if (externalPath) {
      window.open(externalPath);
    }
  };

  return (
    <ListItem button onClick={handleRoute}>
      <ListItemIcon className={classes.itemIcon}>
        {icon(theme.primary)}
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.primaryTitle,
          secondary: classes.secondaryTitle,
        }}
        primary={title}
        secondary={subTitle}
      />
      <ListItemSecondaryAction>
        <IconButton
          className={classes.disableRipple}
          disableRipple={true}
          edge='end'
          onClick={handleRoute}
          aria-label={ariaLabel}
          tabIndex={-1}
        >
          {forwardIcon()}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(settinStyles)(AccountMenuItem);
