import React from 'react';
import { Box, Button } from '@mui/material';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useSelector } from 'react-redux';
import { Theme } from '../../stores/types/theme.interface';
import { useTranslation } from 'react-i18next';

interface SubmitButtonFrameProps {
  onSubmit: () => void;
}

const SubmitButtonFrame: React.FC<SubmitButtonFrameProps> = ({ onSubmit }) => {
  const theme: Theme = useSelector(getTheme);
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        maxWidth: 425,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'background.paper',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        variant='contained'
        onClick={onSubmit}
        sx={{
          width: '100%',
          height: '48px',
          backgroundColor: theme.primary,
          borderRadius: '4px',
          m: '16px',
          textTransform: 'none',
          ':hover': {
            backgroundColor: theme.primary,
            boxShadow: 'none',
          },
        }}
      >
        <Box
          component='span'
          sx={{
            color: 'white',
            fontSize: '16px',
            fontWeight: 600,
            fontFamily: 'Inter',
            lineHeight: 'normal',
          }}
        >
          {i18n.t('submit')}
        </Box>
      </Button>
    </Box>
  );
};

export default SubmitButtonFrame;
