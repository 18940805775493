import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import 'moment/locale/es';
import 'moment/locale/fr';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { paymentIcon } from '../../assets/icons/icons';
import BoltIcon from '../../assets/icons/receipt/Bolt2.svg';
import CalendarIcon from '../../assets/icons/receipt/Calendar.svg';
import ChargerIcon from '../../assets/icons/receipt/Charger_EV.svg';
import ListIcon from '../../assets/icons/receipt/List.svg';
import LocationIcon from '../../assets/icons/receipt/Location.svg';
import PayoutIcon from '../../assets/icons/receipt/Payout_money.svg';
import ReceiptIcon from '../../assets/icons/receipt/Receipt.svg';
import TooltipIcon from '../../assets/icons/receipt/Tooltip.svg';
import {
  useLazyFetchDriverSupportSettingsByChargerIdQuery,
  useLazyFetchReceiptSettingsByChargerNameQuery,
} from '../../services/env.api';
import { useFetchReceiptQuery } from '../../services/receipt.api';
import { styles } from '../../styles/styles.util';
import { readableDuration } from '../../util/date-utils';
import { Assets_URL } from '../../util/js-utility';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { CrediCardIcon } from '../ui-components/credit-card-icon.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import TaxNumbers from './TaxNumbers.component';
import { receiptStyles } from './styles';
import { sortTaxes } from './utils';

export const Receipt = () => {
  const { i18n } = useTranslation();
  const classes = receiptStyles();
  const { receiptId } = useParams();
  const {
    data: receipt,
    isLoading,
    isFetching,
  } = useFetchReceiptQuery(receiptId);
  const [triggerFetchReceiptSettings, { data: receiptSettings }] =
    useLazyFetchReceiptSettingsByChargerNameQuery();
  const [triggerFetchDomainByChargerId, { data: domainByChargerId }] =
    useLazyFetchDriverSupportSettingsByChargerIdQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const transactionId = new URLSearchParams(location.search).get('transaction');
  const chargerName = new URLSearchParams(location.search).get('chargerName');
  // August 10,
  const formatDateMonthOnly = (dateString: string) => {
    return moment(dateString).locale(i18n.language).format('MMMM DD,');
  };

  // August 10, 2022
  const formatDate = (dateString: string) => {
    return moment(dateString).locale(i18n.language).format('MMMM DD, YYYY');
  };

  // 10:00 AM
  const formatTime = (dateString: string) => {
    return moment(dateString).format('h:mm A');
  };

  const formatNumber = (input: number, decimalCount: number) => {
    try {
      return input.toFixed(decimalCount);
    } catch (e) {
      return '0.00';
    }
  };

  useEffect(() => {
    const element = document.getElementById(
      'favicon',
    ) as HTMLAnchorElement | null;
    if (receipt && element) {
      triggerFetchReceiptSettings(receipt.displayId);
      element.href = `${Assets_URL}?resourceId=logo-svg&chargerId=${receipt.displayId}`;
    }
    if (receipt?.displayId) {
      triggerFetchDomainByChargerId(receipt.displayId);
    }
  }, [receipt]);

  useEffect(() => {
    if (receiptSettings) document.title = receiptSettings.companyName;
  }, [receiptSettings]);

  const readableDate = () => {
    const readableStartDate = formatDate(receipt.startTime);
    const readableEndDate = formatDate(receipt.stopTime);

    const readableStartTime = formatTime(receipt.startTime);
    const readableEndTime = formatTime(receipt.stopTime);

    let result = '';
    if (readableStartDate === readableEndDate) {
      result =
        readableStartDate + ' ' + readableStartTime + ' - ' + readableEndTime;
    } else {
      result = `${readableStartDate} ${readableStartTime} -
            ${readableEndDate} ${readableEndTime}`;
    }
    return result;
  };

  const readableLineItemDate = (startDate: string, endDate: string) => {
    const readableStartDate = formatDateMonthOnly(startDate);
    const readableEndDate = formatDateMonthOnly(endDate);

    const readableStartTime = formatTime(startDate);
    const readableEndTime = formatTime(endDate);

    const receiptRadableStartDate = formatDate(receipt.startTime);
    const receiptReadableEndDate = formatDate(receipt.stopTime);
    let result = '';
    // when the start and end dates are not the same, we will show the Month
    if (receiptRadableStartDate !== receiptReadableEndDate) {
      if (readableStartDate === readableEndDate) {
        result =
          readableStartDate + ' ' + readableStartTime + ' - ' + readableEndTime;
      } else {
        result = `${readableStartDate} ${readableStartTime} - ${readableEndDate} ${readableEndTime}`;
      }
    } else {
      // just show the time when the start and end date are the same
      result = `${readableStartTime} - ${readableEndTime}`;
    }
    return result;
  };

  const readableBillingType = (billingType: string) => {
    switch (billingType) {
      case 'BILLED_BY_TIME_PLUGGED_IN':
        return `hr ${i18n.t('receipt_plugged_in')}`;
      case 'BILLED_BY_EFFECTIVE_CHARGING_TIME':
        return `hr ${i18n.t('receipt_charging')}`;
      case 'BILLED_BY_KWH':
        return 'kWh';
      case 'IDLE_RATE':
        return 'min';
      default:
        return `hr ${i18n.t('receipt_charging')}`;
    }
  };

  const readableLineItemPrice = (lineItem: any) => {
    if (lineItem.unitPricingType === 'FREE') {
      return '@ Free';
    }
    // @ $1.00/['hr charging', 'hr plugged in', 'kWh']
    return `@ $${formatNumber(lineItem.unitPrice, 2)}/${readableBillingType(
      lineItem.unitPricingType,
    )}`;
  };

  const readableLineItemDescription = (lineItem: any) => {
    if (
      lineItem.unitPricingType === 'BILLED_BY_KWH' ||
      lineItem.unitPricingType === 'FREE'
    ) {
      // eg: 15.5123 kWh @ $0.50/kWh or 15.5123 kWh @ Free
      return `${formatNumber(
        lineItem.energyDelivered,
        4,
      )} kWh ${readableLineItemPrice(lineItem)}`;
    }

    // eg: X hr X min @ $3.00/['hr charging', 'hr plugged in'] or X hr X min @ Free
    return `${readableDuration(
      moment
        .duration(moment(lineItem.stopTime).diff(moment(lineItem.startTime)))
        .asSeconds(),
    )} ${readableLineItemPrice(lineItem)}`;
  };

  const renderLineItem = (lineItem: any) => {
    return (
      <Grid container item className={classes.mt4}>
        <Grid container item direction='column' xs={9}>
          <Grid item className={clsx(classes.mt4)}>
            <span className={clsx(classes.font14, classes.gray)}>
              {readableLineItemDescription(lineItem)}
            </span>
          </Grid>
          <Grid item className={clsx(classes.mt2)}>
            <span className={clsx(classes.font14, classes.gray)}>
              {readableLineItemDate(lineItem.startTime, lineItem.stopTime)}
            </span>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.mt4)}
          container
          item
          xs={3}
          justifyContent='flex-end'
        >
          <span
            className={clsx(classes.font14, classes.gray)}
          >{`$${formatNumber(lineItem.totalPrice, 2)}`}</span>
        </Grid>
      </Grid>
    );
  };

  const readableTaxDescriptions = (taxCode: string, percentage: any) => {
    switch (taxCode) {
      case 'sales tax':
        return `Sales tax (${percentage}%) incl.`;
      default:
        return `${taxCode} (${percentage}%) incl.`;
    }
  };

  const resolveChargerType = (chargerLevel: string) => {
    switch (chargerLevel) {
      case 'LEVEL_1':
      case 'LEVEL_2':
        return 'AC';
      case 'DC_FAST':
        return 'DC';
      default:
        return 'AC';
    }
  };

  const checkIfBillingTypeEffectiveChargingTime = () => {
    return (
      !receipt.freeCharger &&
      receipt.chargingLineItems &&
      receipt.chargingLineItems[0]['unitPricingType'] ===
        'BILLED_BY_EFFECTIVE_CHARGING_TIME'
    );
  };

  const renderStatusNotification = (status: string, date: string) => {
    let spiel = '';
    switch (status) {
      case 'POWER_LOSS':
        spiel = `Power outage @ ${formatDateMonthOnly(date)} ${formatTime(
          date,
        )}`;
        break;
    }
    return spiel;
  };

  if (isLoading || isFetching) {
    return (
      <div style={{ ...styles.container, minHeight: '100vh' }}>
        <LoadingDots />
      </div>
    );
  }

  return !receipt ? (
    <div style={{ ...styles.container, minHeight: '100vh' }}>
      <span className={clsx(classes.fontInter, classes.weight600)}>
        Receipt not found.
      </span>
    </div>
  ) : (
    <ViewWrapper>
      <Grid className={clsx(classes.fontInter, classes.darkGray)} container>
        <BackButtonHeader
          title={i18n.t('receipt')}
          onBackArrowClicked={
            chargerName && transactionId
              ? () => navigate(`/${chargerName}`)
              : !chargerName && transactionId
              ? () => navigate(-1)
              : null
          }
          isReceipt
        />
        <Grid
          container
          alignItems='center'
          item
          style={{ paddingTop: '20px', marginLeft: 20 }}
        >
          <Grid item xs={1}>
            <img src={ListIcon} alt='' className={classes.displayBlock} />
          </Grid>
          <Grid item xs={7}>
            <span className={classes.weight600}>
              {i18n.t('receipt_charging_summary')}
            </span>
          </Grid>
        </Grid>

        <Grid
          container
          item
          className={clsx(classes.border, classes.m16, classes.p16)}
        >
          <Grid container item aria-label={i18n.t('date_time_session')}>
            <Grid item xs={1} justifyContent='center'>
              <img src={CalendarIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span
                  className={clsx(
                    classes.font14,
                    classes.weight600,
                    classes.preLine,
                  )}
                >
                  {readableDate()}
                </span>
              </Grid>
              {receipt.notifications &&
                receipt.notifications.length > 0 &&
                receipt.notifications[0].status === 'POWER_LOSS' && (
                  <Grid item className={clsx(classes.mt4)}>
                    <span className={clsx(classes.font14, classes.red)}>
                      {renderStatusNotification(
                        receipt.notifications[0].status,
                        receipt.notifications[0].statusTimestamp,
                      )}
                    </span>
                  </Grid>
                )}
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>
                  {readableDuration(receipt.pluggedInTimeSeconds)}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('time')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('location')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={LocationIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {receipt.location.name}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>
                  {receipt.location.streetAddress}
                </span>
              </Grid>
              <Grid item>
                <span
                  className={clsx(classes.font14)}
                >{`${receipt.location.city}, ${receipt.location.stateOrRegion}, ${receipt.location.zipOrPostalCode}`}</span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_location')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('charger_energy_speed')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={ChargerIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {receipt.displayId}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>{`${formatNumber(
                  receipt.maxWattage,
                  1,
                )} kW ${resolveChargerType(receipt.chargerLevel)}`}</span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_charger')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('enerygy_added')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={BoltIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {formatNumber(receipt.energyConsumedKwh, 4)} kWh
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_energy_added')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('session_cost')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={PayoutIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {!receipt.freeCharger && receipt.total > 0
                    ? `$${formatNumber(receipt.total, 2)}`
                    : i18n.t('free')}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('total')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          {receipt.cardLast4 && (
            <Grid container item className={classes.pt20}>
              <Grid item xs={1} marginTop={'2px'}>
                {paymentIcon('#4e5968')}
              </Grid>
              <Grid container item direction='column' xs={11}>
                <Grid
                  container
                  alignItems='center'
                  gap={'4px'}
                  marginTop='-3px'
                  className={clsx(classes.flexCenter)}
                >
                  <CrediCardIcon brand={receipt.cardBrand || ''} />
                  <span className={clsx(classes.font14, classes.weight600)}>
                    {receipt?.cardLast4 || ''}
                  </span>
                </Grid>
                <Grid item className={clsx(classes.mt4)} marginLeft={'2px'}>
                  <span className={clsx(classes.font14, classes.gray)}>
                    {i18n.t('payment_method')}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!receipt.freeCharger && receipt.subTotal > 0 && (
        <>
          {/* separator */}
          <Grid
            container
            className={clsx(classes.borderBottom8, classes.pt20)}
          ></Grid>

          <Grid
            className={clsx(
              classes.fontInter,
              classes.darkGray,
              classes.pt16,
              classes.px16,
            )}
            container
          >
            <Grid container alignItems='center' item>
              <Grid item xs={1}>
                <img
                  src={ReceiptIcon}
                  alt=''
                  className={classes.displayBlock}
                />
              </Grid>
              <Grid item xs={11}>
                <span className={classes.weight600}>
                  {i18n.t('receipt_cost_detail')}
                </span>
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={clsx(classes.border, classes.p16, classes.mt16)}
            >
              {/* Base fee is not yet in the backend
                        <Grid container item>
                            <Grid item xs={8}>
                                <span className={classes.weight500}>Base fee</span>
                            </Grid>
                            <Grid container item xs={4} justify="flex-end">
                                <span className={clsx(classes.weight500)}>$1.00</span>
                            </Grid>
                        </Grid>
                        */}

              <Grid container item>
                <Grid container item xs={8}>
                  <Grid item>
                    <span className={classes.weight500}>{`${i18n.t(
                      'charging',
                    )}`}</span>
                  </Grid>
                  {checkIfBillingTypeEffectiveChargingTime() && (
                    <Grid item className={classes.pl4}>
                      <Tippy
                        content={
                          <span>
                            Charging time may be shorter than plugged in time
                            and is adjusted based on the actual speed of charge
                          </span>
                        }
                        arrow
                        allowHTML
                        placement='top-start'
                        interactive
                        maxWidth={300}
                        offset={[-92, 10]}
                        className={clsx(
                          classes.font14,
                          classes.bgDarkGray,
                          classes.white,
                          classes.py12px16,
                          classes.roundBorder,
                          classes.weight500,
                        )}
                      >
                        <img
                          src={TooltipIcon}
                          alt=''
                          className={clsx(
                            classes.displayBlock,
                            classes.pointer,
                          )}
                        />
                      </Tippy>
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span className={clsx(classes.weight500)}>{`$${
                    receipt.chargingLineItems &&
                    formatNumber(
                      receipt.chargingLineItems
                        .map((lineItem) => lineItem.totalPrice)
                        .reduce((a, b) => a + b, 0),
                      2,
                    )
                  }`}</span>
                </Grid>
              </Grid>

              {receipt.chargingLineItems &&
                // prevent displaying lineItems if the unitPricingType only contains FREE
                receipt.chargingLineItems.some(
                  (lineItem) => lineItem.unitPricingType !== 'FREE',
                ) &&
                receipt.chargingLineItems.map((lineItem) =>
                  renderLineItem(lineItem),
                )}

              {receipt.idleLineItems && (
                <Grid container item className={classes.pt20}>
                  <Grid item xs={8}>
                    <span className={classes.weight500}>
                      {i18n.t('receipt_idling')}
                    </span>
                  </Grid>
                  <Grid container item xs={4} justifyContent='flex-end'>
                    <span className={clsx(classes.weight500)}>{`$${
                      receipt.idleLineItems &&
                      formatNumber(
                        receipt.idleLineItems
                          .map((lineItem) => lineItem.totalPrice)
                          .reduce((a, b) => a + b, 0),
                        2,
                      )
                    }`}</span>
                  </Grid>
                </Grid>
              )}

              {receipt.idleLineItems &&
                receipt.idleLineItems.map((lineItem) => {
                  return (
                    <Grid container item className={classes.mt4}>
                      <Grid container item direction='column' xs={8}>
                        <Grid item className={clsx(classes.mt4)}>
                          <span
                            className={clsx(classes.font14, classes.gray)}
                          >{`${readableDuration(
                            lineItem.duration,
                          )} @ $${formatNumber(
                            lineItem.unitPrice,
                            2,
                          )}/${readableBillingType(
                            lineItem.unitPricingType,
                          )}`}</span>
                        </Grid>
                        <Grid item className={clsx(classes.mt2)}>
                          <span className={clsx(classes.font14, classes.gray)}>
                            {readableLineItemDate(
                              lineItem.startTime,
                              lineItem.stopTime,
                            )}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid
                        className={clsx(classes.mt4)}
                        container
                        item
                        xs={4}
                        justifyContent='flex-end'
                      >
                        <span
                          className={clsx(classes.font14, classes.gray)}
                        >{`$${formatNumber(lineItem.totalPrice, 2)}`}</span>
                      </Grid>
                    </Grid>
                  );
                })}

              <Grid
                container
                item
                className={clsx(classes.borderBottom2, classes.mt16)}
              ></Grid>

              <Grid container item className={classes.pt16}>
                <Grid item xs={8}>
                  <span className={classes.weight500}>
                    {i18n.t('receipt_subtotal')}
                  </span>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span className={clsx(classes.weight500)}>{`$${formatNumber(
                    receipt.subTotal,
                    2,
                  )}`}</span>
                </Grid>
              </Grid>

              {receipt.discountPercentage > 0 && (
                <Grid container item className={classes.mt12}>
                  <Grid item xs={8}>
                    <span className={classes.weight500}>
                      {i18n.t('receipt_discount')}{' '}
                      {`(${
                        receipt.discountPercentage === 100
                          ? i18n.t('free')
                          : receipt.discountPercentage + '%'
                      })`}
                    </span>
                  </Grid>
                  <Grid container item xs={4} justifyContent='flex-end'>
                    <span
                      className={clsx(classes.weight500, classes.green)}
                    >{`- $${formatNumber(
                      receipt.subTotal - receipt.total,
                      2,
                    )}`}</span>
                  </Grid>
                </Grid>
              )}

              {receipt.taxRates &&
                Object.entries(sortTaxes(receipt.taxRates)).map(
                  ([key, value]) => {
                    return (
                      <Grid container item className={classes.mt12}>
                        <Grid item xs={8}>
                          <span className={classes.weight500}>
                            {readableTaxDescriptions(key, value)}
                          </span>
                        </Grid>
                        <Grid container item xs={4} justifyContent='flex-end'>
                          <span
                            className={clsx(classes.weight500)}
                          >{`$${formatNumber(
                            receipt.taxedAmounts[key],
                            2,
                          )}`}</span>
                        </Grid>
                      </Grid>
                    );
                  },
                )}

              <Grid
                container
                item
                className={clsx(classes.borderBottom2, classes.mt16)}
              ></Grid>

              <Grid container item className={classes.pt16}>
                <Grid item xs={8}>
                  <span className={classes.weight500}>{i18n.t('total')}</span>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span className={clsx(classes.weight500)}>{`$${formatNumber(
                    receipt.total,
                    2,
                  )}`}</span>
                </Grid>
              </Grid>

              {receipt?.amountUnpaid > 0 && (
                <Grid container item className={classes.pt16}>
                  <Grid item xs={8}>
                    <span className={classes.weight500}>
                      {i18n.t('amount_paid')}
                    </span>
                  </Grid>
                  <Grid container item xs={4} justifyContent='flex-end'>
                    <span className={clsx(classes.weight500)}>{`$${formatNumber(
                      receipt.amountPaid,
                      2,
                    )}`}</span>
                  </Grid>
                </Grid>
              )}

              <Grid container item className={classes.pt16}>
                <Grid item xs={8}>
                  <span className={classes.weight500}>
                    {i18n.t('currency')}
                  </span>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span className={clsx(classes.weight500)}>
                    {receipt.currency}
                  </span>
                </Grid>
              </Grid>

              {receipt?.amountUnpaid > 0 && (
                <>
                  <Grid container item className={classes.pt16}>
                    <Grid item>
                      <span className={clsx(classes.font14, classes.gray6)}>
                        {i18n.t('partial_payment_desc')}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    className={clsx(classes.borderBottom2, classes.mt16)}
                  ></Grid>
                  <Grid container item className={classes.pt16}>
                    <Grid item xs={8}>
                      <span
                        className={clsx(
                          classes.weight500,
                          classes.alertsNegative2,
                        )}
                      >
                        {i18n.t('amount_unpaid')}
                      </span>
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                      <span
                        className={clsx(
                          classes.weight500,
                          classes.alertsNegative2,
                        )}
                      >{`$${formatNumber(receipt.amountUnpaid, 2)}`}</span>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        className={clsx(
          classes.fontInter,
          classes.darkGray,
          classes.pt8,
          classes.px16,
        )}
        container
      >
        <Grid item>
          <span className={clsx(classes.font14, classes.gray6)}>{`${i18n.t(
            'receipt_price_set_by',
          )} ${receipt?.companyName}`}</span>
        </Grid>
        <TaxNumbers taxRates={receipt.taxRates} />
        {domainByChargerId && (
          <Grid item marginTop={2}>
            <span className={clsx(classes.font14, classes.gray6)}>
              {domainByChargerId.whiteLabellingId === 'IVY'
                ? i18n.t('credit_card_show_changes_ivy')
                : i18n.t('credit_card_show_changes_other')}
            </span>
          </Grid>
        )}
        <Grid container item justifyContent='center' className={classes.p32}>
          <img
            src={`${Assets_URL}?resourceId=logo-svg&chargerId=${receipt.displayId}`}
            className={clsx(classes.h50, classes.maxWidth220)}
            style={{ height: 50, maxWidth: 220 }}
            alt='Company Logo'
          />
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};
