import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import QuestionHelp from '../../assets/images/charger/question_help.svg';
import ChevronDown from '../../assets/icons/chevron_down.svg';
import ChevronUp from '../../assets/icons/chevron_up.svg';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 8px',
    borderRadius: '8px',
    background: '#F2F4F6',
    marginTop: '24px',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    pag: '4px',
    padding: '0px 8px',
    marginTop: '8px',
  },
  contentSection: {
    color: '#202223',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  leftSection: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  rightSection: {
    display: 'flex',
    cursor: 'pointer',
  },
  questionHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

export const ChargerQuestionHelpDropdown = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.wrapper}>
      <div className={classes.topSection}>
        <div className={classes.leftSection}>
          <img src={QuestionHelp} alt='Question Help' width={20} height={20} />
          <Typography className={classes.questionHeader}>
            {t('question_help_text')}
          </Typography>
        </div>
        <div
          className={classes.rightSection}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <img
              src={ChevronUp}
              alt='Up'
              width={24}
              height={24}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setIsOpen(!isOpen);
                }
              }}
            />
          ) : (
            <img
              src={ChevronDown}
              alt='Down'
              width={24}
              height={24}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setIsOpen(!isOpen);
                }
              }}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={classes.bottomSection}>
          <Typography className={classes.contentSection}>
            {t('question_help_text_desc_2')}
          </Typography>
        </div>
      )}
    </div>
  );
};
