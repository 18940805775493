import { Info } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { getTheme } from '../../stores/selectors/theme.selector';
import CustomButtonGroup from '../react-native-material-wrapper/button-group.component';
import CustomButton from '../react-native-material-wrapper/button.component';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#EBF8FF',
    boxSizing: 'border-box',
    borderTop: '2px solid #F2F4F6',
    '& button': {
      width: '100% !important',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    maxWidth: '425px',
    width: '100%',
    height: 48,
    '&:hover': {
      boxShadow: 'none',
    },
    '& button': {
      width: '50% !important',
      height: 48,
    },
  },
  buttonWrapper: {
    padding: '16px 24px 24px 24px',
    backgroundColor: '#EBF8FF',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  buttonWrapperInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .info-text': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#202223',
    },
  },
  buttonItem: {
    width: '50vw',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: '4px !important',
    textTransform: 'inherit',
    '&:hover': {
      boxShadow: 'none',
    },
    borderRight: '0 !important',
  },
  loginInstruction: {
    display: 'flex',
    height: '24px',
    gap: '8px',
    alignItems: 'center',
  },
}));

const SignInSignUpButtons = ({ showLogin, showRegister }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const auth = useAuth();
  const theme = useSelector(getTheme);
  if (auth.isLogined) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.buttonWrapper}>
        <div className={classes.buttonWrapperInfo}>
          <Info style={{ color: theme.primary }} />
          <div className='info-text'>{i18n.t('signup_info_text')}</div>
        </div>
        <CustomButtonGroup
          disableElevation
          variant='contained'
          className={classes.buttonGroup}
        >
          <CustomButton
            disableElevation
            className={classes.buttonItem}
            style={{
              backgroundColor: theme.primary,
              color: '#ffffff',
              padding: '12px 16px',
            }}
            onClick={showLogin}
          >
            {i18n.t('log_in')}
          </CustomButton>
          <CustomButton
            className={classes.buttonItem}
            style={{
              backgroundColor: '#ffffff',
              color: theme.primary,
              border: '1px solid #D1D6DB',
            }}
            onClick={showRegister}
          >
            {i18n.t('sign_up')}
          </CustomButton>
        </CustomButtonGroup>
      </div>
    </div>
  );
};

export default SignInSignUpButtons;
